<template>
  <div class="col">
      <div class="listbox d-flex align-items-center">{{ data }}</div>
  </div>
</template>
<script>
import TemplateFacility from './TemplateFacility';

export default {
  name: 'FacilityTheme0',
  mixins: [TemplateFacility],
  props: {
    data: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
  .place-offer .listbox {
      color: #000000;
      font-size: 12px;
      font-weight: 400;
      position: relative;
      padding-right: 20px;
      margin-bottom: 5px;
  }
  .place-offer .listbox::before {
      content: '';
      position: absolute;
      top: 4px;
      right: 0;
      width: 12px;
      height: 11px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url(/assets/img/theme1/check3.png);
  }
  @media (min-width: 1400px){
      .row-cols-xxl-6>* {
          flex: 0 0 auto;
          width: 16.6666666667%;
      }
  }
  @media (min-width: 992px){
      .row-cols-lg-4>* {
          flex: 0 0 auto;
          width: 25%;
      }
  }
  @media (min-width: 768px){
      .row-cols-md-3>* {
          flex: 0 0 auto;
          width: 33.3333333333%;
      }
  }
  @media (min-width: 576px){
      .row-cols-sm-2>* {
          flex: 0 0 auto;
          width: 50%;
      }
  }

</style>
